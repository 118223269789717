import React, { createContext as reactCreateContext, useContext as reactUseContext, useState } from "react";

// Setting a default value that matches the shape of the context data
const defaultContextValue = {
    isAuthenticatedProfileState: false,
    isAuthenticatedDispatch: () => {}
};

const context = reactCreateContext(defaultContextValue);


export const useContextSignIn = () => {
    try {
        const c = reactUseContext(context);
        if (c === undefined) {
            throw new Error("Error: Unable to find any context, make sure you're using this hook inside a Provider with a context value");
        }
        return c;
    } catch (e) {
        console.log(e);
    }

};

export const AuthContextProvider = ({ children }) => {
    const [isAuthenticatedProfileState, isAuthenticatedDispatch] = useState(false);

    return <context.Provider value={{ isAuthenticatedProfileState, isAuthenticatedDispatch }}>{children}</context.Provider>;
};