/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "@fontsource-variable/work-sans"
import "./src/styles/global.css"
import React from "react"
import {
  AuthContextProvider,
  useContextSignIn,
} from "./src/utils/AuthContextProvider"

const Wrapper = ({ element }) => {
  const props = useContextSignIn()

  return <AuthContextProvider>{element}</AuthContextProvider>
}

export const wrapRootElement = ({ element }) => {
  return <Wrapper element={element} />
}

// Add the onRouteUpdate function
export const onRouteUpdate = ({ location }) => {
  const links = document.querySelectorAll(".customLink")

  links.forEach(link => {
    if (link.getAttribute("href") === location.pathname) {
      link.classList.add("active")
    } else {
      link.classList.remove("active")
    }
  })
}
